import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

//Components
import VideoSection from "../components/videosection"
import Main from '../components/Shulbius/main'
import ClipConcept from '../components/Shulbius/clipconcept'
import Contact from '../components/Shulbius/contact'

const IndexPage = () => (
  <Layout>
    <Seo title="Shulbius" description="Aquí debería ir la descripción de la sección de contacto" />
    <VideoSection sectionId="shulbius" videoSrc="//www.youtube.com/embed/PixRlHAyfF0?playlist=PixRlHAyfF0&amp;autoplay=1&amp;version=3&amp;rel=0&amp;controls=1&amp;showinfo=0&amp;mute=1&amp;loop=1&amp;iv_load_policy=3&amp;modestbranding=1&amp;vq=hd720&amp;"/>
    <Main />
    <ClipConcept/>
    <Contact/>
  </Layout>
)

export default IndexPage
