import React, { useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import OwlCarousel from "react-owl-carousel3";
import { connect } from "react-redux";
import { getQuality } from "../../helpers/helper.rendering";
import IconRightSvg from "../../images/svg/icon-right.svg";
import {getFilterReverseDevs, getLastFeatured, getLocation, getFakeAddress} from "../../helpers/helper.developments"
const options = {
    loop: true,
    smartSpeed: 1000,
    stagePadding: 0,
    margin: 0,
    responsive: {
        0: {
            items: 1,
        }
    },
}

const MainDevShulbius = ({properties, developmentsFilters, api_key, dispatch}) => {

    const slider = useRef()

    const mockRealEstate = {
        sections: {
            shulbius: {
                mainDev: {
                    subtitle: "SHULBIUS CAFE",
                    location: "Hipólito Yrigoyen al 2400, Florida, Vicente López",
                    text: "Un nuevo emprendimiento de la familia Shulbius pensado para vos.",
                    btn: {
                        link: "/emprendimiento/hipolito-yrigoyen-al-2400-florida/22660",
                        text: "Conocé más"
                    }
                }
            }
        }
    }

    const [featured,setFeatured] = useState({})

    useEffect(() => {
        setFeatured(getLastFeatured(getFilterReverseDevs(developmentsFilters?.data?.objects, true)))
    },[developmentsFilters])

    return !featured?.id ? '' :  (
        <section id="shulbius-maindev" className="mb-5">
            <div className="wrapper d-flex flex-column">
                <div className="row">
                    <div className="col-12 gallery">
                        <OwlCarousel ref={slider}
                            {...options}
                            className="overflow-hidden">
                            {featured.photos.map((photo, index) => (
                                <div key={index}>
                                    <img className="object-fit position-center" src={getQuality(photo)} 
                                        alt={featured.name} />
                                </div>
                            ))}
                        </OwlCarousel>
                        <div className="arrows align-self-center">
                            <div className="arrow" onClick={() => slider.current.prev()}>{"‹"}</div>
                            <div className="arrow" onClick={() => slider.current.next()}>{"›"}</div>
                        </div>
                    </div>
                    <div className="col-12 text-content">
                        <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-end justify-content-between mt-5">
                            <div className="d-flex flex-column mb-5 mb-lg-0">
                                <h3 className="mb-0">{featured.name}</h3>
                                <h3 className="mb-0">{getFakeAddress(featured) + ", " + getLocation(featured)}</h3>
                                <h4>{featured.publication_title}</h4> {/* Texto fijo como en la web antigua */}
                            </div>
                            <a target={"_blank"} href={`/emprendimientos/${featured.id}`} className="btn align-items-center d-flex me-3">
                                Conocé más
                                <IconRightSvg className="icon ms-3"/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default connect(state => ({
    properties: state.properties.featured,
    developmentsFilters: state.developments.developmentsFilters,
    api_key:state.settings.keys.tokko
}),null)(MainDevShulbius);