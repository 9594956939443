import React from "react";
import Breadcrumbs from "../breadcrumbs";
import Developments from "../Emprendimientos/developments";
import Maindevshulbius from "./maindevshulbius";

const Main = () => {

    return (
        <section class="main-shulbius">
            <div class="container-fluid py-4">
                <div class="row center align-items-center">
                    <div className="col-12">
                        <Breadcrumbs
                            props={[
                            { name: "Home", route: "/", location: "" },
                            { name: "Shulbius", route:   "", location: ""},
                            ]}
                        />
                    </div>
                    <div className="col-12 main-dev-wrapper">
                        <h2>Emprendimiento Destacado</h2>
                        <Maindevshulbius/>
                    </div>
                </div>
            </div>
            <section class="project-shulbius">
                <div class="container-fluid">
                    <h2>Proyectos</h2>
                    <h5>Colección Shulbius by Bellagamba</h5>
                    <div class="wrapper-projects">
                        <Developments />
                    </div>
                </div>
            </section>
        </section>
    )
}

export default Main;