import React from "react";
import shulbiusImg from "../../images/shulbius/foto_01.png";
import woodImg from "../../images/shulbius/foto_02.png";

const ClipConcept = () => {

    return (
        <div class="clip-concept">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="clip-concept_int">
                            <div class="row justify-content-between">
                                <div class="col-lg-6 d-flex align-items-start flex-column">
                                    <div class="texto-01">
                                        <div class="linea"></div>
                                        Hay una forma en la que nos imaginamos viviendo. Sensaciones incomparables, detalles únicos, coordenadas ideales: el escondite perfecto donde escaparse de todo en <span class="italic">abrir y cerrar de ojos</span>.
                                    </div>
                                    <div class="mt-auto d-none d-lg-block">
                                        <img class="mt-auto foto-02" src={woodImg} alt="Wood"/>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <img src={shulbiusImg} class="foto-01" alt="Shulbius"/>
                                    <div class="texto-02">
                                        <div class="linea"></div>
                                        Tu hogar es el único lugar en el mundo donde las cosas son como vos querés que sean. Y eso es Shulbius, la combinación perfecta entre diseño, lugar, ambiente, comodidades y vos. Una <span class="italic">experiencia de vida</span> donde se cumplen todas tus expectativas.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClipConcept;