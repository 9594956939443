import React from "react";
import Formulario from "../Contact";

const Contact = () => {

    return (
        <section class="formulario-shulbius">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-6">
                        <h5><div class="linea"></div>Comenzá una nueva vida. Pedí asesoramiento para ver cual es el Shulbius que más se adapta a vos.</h5>
                    </div>
                    <div class="col-lg-6 mt-5 mt-lg-0">
                        <Formulario from={"contact"} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact;